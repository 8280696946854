@import url('https://fonts.googleapis.com/css2?family=Cinzel&family=Fauna+One&family=Inter:wght@400;600;700&family=Odibee+Sans&family=Open+Sans&family=Orbitron&family=Roboto:wght@100;400;700&family=Rubik:wght@400;500;700&family=Work+Sans:wght@300&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  margin: auto;
}
